<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="primary" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Media</v-tab>
                      <v-tab-item>
                        <h4 class="mt-5">Logo</h4>
                        <ModuleMedia moduleType="image" moduleOf="admin_logo" moduleMultiple="false"
                          :moduleImages="moduleImages" :moduleImagesOld="moduleImagesOld"></ModuleMedia>

                        <h4 class="mt-5">File</h4>
                        <ModuleMedia moduleType="image" moduleOf="admin_video" :moduleMultiple="false"
                          :moduleFiles="moduleFiles" :moduleFilesOld="moduleFilesOld" />

                        <h4>Login Background</h4>
                        <ModuleMedia moduleType="image" moduleOf="admin_bg" moduleMultiple="false"
                          :moduleImages="moduleBgImages" :moduleImagesOld="moduleBgImagesOld"></ModuleMedia>
                        <v-text-field v-model="editedItem.video_expiry_start_date" type="date"
                          label="Video Expiry Start Date*" :counter="100" required></v-text-field>

                        <v-text-field v-model="editedItem.video_expiry_end_date" type="date"
                          label="Video Expiry End Date*" :counter="100" required></v-text-field>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors != undefined && errors.length >= 0" role="alert"
                      v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0" />
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table">
              </v-skeleton-loader>
              <v-card-title>
                Admin Settings
                <v-spacer></v-spacer>
                <v-text-field @keydown.enter="searchQueryData" append-icon="search" label="Search" single-line
                  hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                :server-items-length="totalRecords" :footer-props="{
                itemsPerPageOptions: rowsPerPageItems,
                showFirstLastPage: true
              }" :loading="loading" v-show="!isLoaded" mobile-breakpoint="800" class="elevation-0">
                <template v-slot:item.image_public_url="{ item }">
                  <div class="text-truncate">
                    <img :src="item.image_public_url" style="width: 50%" />
                  </div>
                </template>
                <template v-slot:item.bg_image_public_url="{ item }">
                  <div class="text-truncate">
                    <img :src="item.bg_image_public_url" style="width: 50%" />
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="hasPermission('adminsettings.edit')" color="primary" @click="showEditDialog(item)"
                          dark class="mr-2" v-on="on">
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADMINSETTINGS_LIST,
  UPDATE_ADMINSETTING,
  CLEAR_ADMINSETTING_ERRORS,
  GET_ADMINSETTING_DETAILS
} from "@/core/services/store/adminsettings.module";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";
import { bus } from "@/main";
// import Confirmation from "@/view/content/Confirmation";

export default {
  name: "admin-settings",
  components: {
    ModuleMedia
  },
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleBgImages: [],
      moduleFiles: [],
      moduleFilesOld: [],
      moduleImagesOld: [],
      moduleBgImagesOld: [],
      headers: [
        {
          text: "Logo",
          value: "image_public_url",
          name: "image_public_url",
          filterable: false,
          sortable: false
        },
        {
          text: "Login Background",
          value: "bg_image_public_url",
          name: "bg_image_public_url",
          filterable: false,
          sortable: false
        },
        {
          text: "Action",
          value: "actions",
          name: "name",
          filterable: true,
          sortable: false
        }
      ],
      items: [],
      dialog: false,
      show: false,
      editedItem: {
        video_expiry_start_date: "",
        video_expiry_end_date: ""
      }
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_ADMINSETTING_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
      if (!val) this.moduleFiles = [];
      if (!val) this.moduleFilesOld = [];
      if (!val) this.moduleBgImages = [];
      if (!val) this.moduleBgImagesOld = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
        i +
        "][data]=" +
        this.headers[i].value +
        "&columns[" +
        i +
        "][name]=" +
        this.headers[i].name +
        "&columns[" +
        i +
        "][searchable]=" +
        this.headers[i].filterable +
        "&columns[" +
        i +
        "][orderable]=" +
        this.headers[i].sortable +
        "&columns[" +
        i +
        "][search][value]=" +
        "&columns[" +
        i +
        "][search][regex]=" +
        false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings", route: "admin-settings" },
      { title: "Admin Settings" }
    ]);

    bus.$on("moduleImagesUploaded", moduleImages => {
      if (moduleImages.type === "admin_logo") {
        this.moduleImages = moduleImages.arr;
      } else if (moduleImages.type === "admin_video") {
        this.moduleFiles = moduleImages.arr;
      } else {
        this.moduleBgImages = moduleImages.arr;
      }
    });

    bus.$on("moduleImagesUploadedOld", moduleImagesOld => {
      if (moduleImagesOld.type === "admin_logo") {
        this.moduleImagesOld = moduleImagesOld.arr;
      } else {
        this.moduleBgImagesOld = moduleImagesOld.arr;
      }
    });
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map(key => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          (params.sortBy.length === 0 ? "created_at" : params.sortBy) +
          "&order[0][dir]=" +
          (params.sortDesc[0] ? "desc" : "asc");
        this.$store
          .dispatch(ADMINSETTINGS_LIST, { q: query, columns: this.columns })
          .then(res => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total
            });
          });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_ADMINSETTING_DETAILS, item.id).then(data => {
        let adminSettingImages = [];
        let adminSettingLoginImages = [];
        this.editedItem = data.records || {};
        if (data.records.image_public_url !== null) {
          adminSettingImages.push(data.records);
        }
        if (data.records.bg_image_public_url !== null) {
          adminSettingLoginImages.push(data.records);
        }
        this.moduleImagesOld = adminSettingImages;
        this.moduleBgImagesOld = adminSettingLoginImages;
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      if (
        this.moduleImages.length > 0 ||
        this.moduleBgImages.length > 0 ||
        this.moduleFiles.length > 0
      ) {
        let mFiles = this.moduleImages;
        let bFiles = this.moduleBgImages;
        let vFiles = this.moduleFiles;
        let cFiles = mFiles.concat(bFiles, vFiles);
        let that = this;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
              .dispatch("delete_uploaded_image", {
                files: cFiles,
                type: "modal",
              })
              .then(() => {
                that.moduleImages = [];
                that.moduleBgImages = [];
                that.moduleFiles = [];
                that.dialog = false;
                bus.$emit("moduleImagesDeletedByModal");
              });
          },
        });
      } else {
        this.dialog = !this.dialog;
      }
    },

    saveItem(item) {
      /* this is used for both creating and updating API records
     the default method is POST for creating a new item */
      let method = UPDATE_ADMINSETTING;
      let id = item.id;
      this.$set(item, "image", this.moduleImages);
      this.$set(item, "video_url", this.moduleFiles);
      this.$set(item, "bg_image", this.moduleBgImages);

      this.$set(item, "video_expiry_start_date", this.editedItem.video_expiry_start_date);
      this.$set(item, "video_expiry_end_date", this.editedItem.video_expiry_end_date);
      
      let data = {
        data: item
      };
      if (id) {
        // if the item has an id, we're updating an existing item
        method = UPDATE_ADMINSETTING;
        // delete data.data.id;
      }
      this.$store.dispatch(CLEAR_ADMINSETTING_ERRORS);
      // save the record
      this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
        this.editedItem = {};
        this.dialog = !this.dialog;
        bus.$emit("moduleImagesDeletedByModal");
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.adminsettings.errors
    })
  }
};
</script>
