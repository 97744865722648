<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              class="uploaded-files"
              v-for="img in uploadedImagesOld"
              :key="img.id"
            >
              <v-img :src="getOldImagePublicUrl(img)"></v-img>
              <v-btn
                dark
                x-small
                color="red"
                @click="removeUploadedImageOld(img)"
              >
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            dark
            class="ml-auto ma-3"
            style="width:100%"
            @click="uploadImages"
            v-if="isImagesLoaded"
          >
            Upload File
            <v-icon class="ml-2" small>fas fa-upload</v-icon>
          </v-btn>
          <file-pond
            name="files"
            ref="pond"
            maxFileSize="10MB"
            v-if="multipleCheck"
            label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
            :allow-multiple="false"
            accepted-file-types="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
            v-model="images"
            @init="handleFilePondInit"
            @addfile="handleFileAdded"
            @removefile="handleFileRemoved"
          />
        </v-col>
        <v-col cols="6" sm>
          <v-row>
            <v-col
              cols="6"
              class="uploaded-files"
              v-for="image in uploadedImages"
              :key="image.id"
            >
              <v-img :src="image.url"></v-img>
              <v-btn
                dark
                x-small
                color="red"
                @click="removeUploadedImage(image)"
              >
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { bus } from "@/main";
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);
export default {
  name: "module-images",
  props: ["moduleImagesOld", "moduleOf", "moduleMultiple"],
  data() {
    return {
      images: [],
      uploadedImages: [],
      uploadedImagesOld: [],
      isImagesLoaded: false,
      multipleCheck: true,
      mModuleOf: "",
      mModuleMultiple: ""
    };
  },
  watch: {
    moduleImagesOld: {
      handler() {
        this.uploadedImagesOld = this.moduleImagesOld;
        this.multipleCheck = this.uploadedImagesOld.length === 0;
      },
      deep: true
    },
    moduleOf: {
      handler() {
        this.mModuleOf = this.moduleOf;
      },
      deep: true
    },
    moduleMultiple: {
      handler() {
        this.mModuleMultiple = this.moduleMultiple;
      },
      deep: true
    }
  },
  mounted() {
    this.uploadedImagesOld = this.moduleImagesOld;
    this.multipleCheck = this.uploadedImagesOld.length === 0;
    this.mModuleOf = this.moduleOf;
    this.mModuleMultiple = this.moduleMultiple;
    bus.$on("moduleImagesDeletedByModal", () => {
      this.images = [];
      this.uploadedImages = [];
      this.isImagesLoaded = false;
    });
    // bus.$on('moduleModalClosed', () => {
    //     this.$refs.pond.removeFiles();
    // });
    // bus.$on('editModalTriggered', () => {
    //   console.log(123);
    //   // this.uploadedImagesOld = moduleImages;
    // });
  },
  methods: {
    handleFileAdded(e) {
      !e ? (this.isImagesLoaded = true) : (this.isImagesLoaded = false);
      // const formData = new FormData();
      // formData.append('file', f.file, f.file.name);
      // formData.append('type', 'image');
      // this.uploadImage(formData);
    },
    handleFileRemoved() {
      if (this.images.length === 0) {
        this.isImagesLoaded = false;
      }
      // const formData = new FormData();
      // formData.append('file', f.file, f.file.name);
      // formData.append('type', 'image');
      // this.uploadImage(formData);
    },
    uploadImages() {
      const formData = new FormData();
      this.images.forEach(v => {
        formData.append("files[]", v.file, v.file.name);
      });
      formData.append("model", this.mModuleOf);
      // let method = 'UPLOAD_' + this.mModuleOf + '_IMAGE';
      // this.$store.dispatch(CLEAR_LISTING_ERRORS);
      this.$store.dispatch("upload_image", formData).then(data => {
        data.records.forEach(v => {
          this.uploadedImages.push(v);
        });
        this.$refs.pond.removeFiles();
        this.multipleCheck = false;
        this.isImagesLoaded = false;
        bus.$emit("moduleImagesUploaded", {
          arr: this.uploadedImages,
          type: this.mModuleOf
        });
      });
    },
    removeUploadedImage(image) {
      let that = this;
      // let method = 'DELETE_UPLOADED' + this.mModuleOf + 'IMAGE';
      const index = this.uploadedImages.findIndex(element => {
        if (element.path === image.path) {
          return true;
        }
      });
      if (index > -1) {
        this.$store
          .dispatch("delete_uploaded_image", {
            file: image,
            model: this.mModuleOf,
            type: "temp"
          })
          .then(() => {
            that.uploadedImages.splice(index, 1);
            bus.$emit("moduleImagesUploaded", {
              arr: this.uploadedImages,
              type: this.mModuleOf
            });
            this.multipleCheck = true;
          });
      }
    },
    removeUploadedImageOld(image) {
      let that = this;
      let uFile = null;
      // let method = 'DELETE_UPLOADED' + this.mModuleOf + 'IMAGE';
      const index = this.uploadedImagesOld.findIndex(element => {
        if (element.path === image.path) {
          return true;
        }
      });
      if (image.hasOwnProperty("image")) {
        uFile = image.image;
      } else if (image.hasOwnProperty("site_logo_image")) {
        uFile = image.site_logo_image;
      } else if (
        image.hasOwnProperty("admin_logo_image") &&
        this.mModuleOf === "admin_logo"
      ) {
        uFile = image.admin_logo_image;
      } else if (
        image.hasOwnProperty("admin_login_bg_image") &&
        this.mModuleOf === "admin_bg"
      ) {
        uFile = image.admin_login_bg_image;
      } else if (
        image.hasOwnProperty("section_1_image") &&
        this.mModuleOf === "home_page_section_1_image"
      ) {
        uFile = image.section_1_image;
      } else if (
        image.hasOwnProperty("section_2_image") &&
        this.mModuleOf === "home_page_section_2_image"
      ) {
        uFile = image.section_2_image;
      } else if (
        image.hasOwnProperty("main_image") &&
        this.mModuleOf === "ss_main_image"
      ) {
        uFile = image.main_image;
      } else if (
        image.hasOwnProperty("cover_image") &&
        this.mModuleOf === "ss_cover_image"
      ) {
        uFile = image.cover_image;
      } else if (
        image.hasOwnProperty("author_image") &&
        this.mModuleOf === "ss_author_image"
      ) {
        uFile = image.author_image;
      }
      if (index > -1) {
        this.$store
          .dispatch("delete_uploaded_image", {
            file: uFile,
            model: this.mModuleOf,
            type: "old"
          })
          .then(() => {
            that.uploadedImagesOld.splice(index, 1);
            bus.$emit("moduleImagesUploadedOld", {
              arr: this.uploadedImagesOld,
              type: this.mModuleOf
            });
            this.multipleCheck = true;
          });
      }
    },
    getOldImagePublicUrl(img) {
      if (
        img &&
        img.hasOwnProperty("bg_image_public_url") &&
        this.mModuleOf === "admin_bg"
      ) {
        return img.bg_image_public_url;
      } else if (
        img &&
        img.hasOwnProperty("section_1_image_public_url") &&
        this.mModuleOf === "home_page_section_1_image"
      ) {
        return img.section_1_image_public_url;
      } else if (
        img &&
        img.hasOwnProperty("section_2_image_public_url") &&
        this.mModuleOf === "home_page_section_2_image"
      ) {
        return img.section_2_image_public_url;
      } else if (
        img &&
        img.hasOwnProperty("main_image_public_url") &&
        this.mModuleOf === "ss_main_image"
      ) {
        return img.main_image_public_url;
      } else if (
        img &&
        img.hasOwnProperty("cover_image_public_url") &&
        this.mModuleOf === "ss_cover_image"
      ) {
        return img.cover_image_public_url;
      } else if (
        img &&
        img.hasOwnProperty("author_image_public_url") &&
        this.mModuleOf === "ss_author_image"
      ) {
        return img.author_image_public_url;
      } else {
        return img.image_public_url;
      }
    },
    handleFilePondInit() {
      // console.log('FilePond has initialized');
      // FilePond instance methods are available on `this.$refs.pond`
    }
  },
  components: {
    FilePond
  },
  computed: {}
};
</script>
