<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="primary" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <!-- <v-btn v-if="hasPermission('users.create')" color="primary" dark class="ma-3 ml-0" v-on="on">
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn> -->
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit {{ editedItem.name }}</span>
                    <!-- <span v-else>Create Team Member</span> -->
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form ref="form" v-model="validationRules.valid" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedItem.name" :counter="50"
                                    :rules="validationRules.nameRules" label="Name*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedItem.email" :counter="100"
                                    :rules="validationRules.emailRules" label="Email*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedItem.designation" :counter="100"
                                    :rules="validationRules.designationRules" label="Designation*"
                                    required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedItem.password" :counter="20" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show ? 'text' : 'password'" @click:append="show = !show" label="Password"
                                    required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-select clearable v-model="editedItem.department" :items="departments"
                                    item-text="title" item-value="value" label="Department*">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Description*</label>
                                  <editor :api-key="tinyKey" ref="et_editor" v-model="editedItem.description"
                                    :init="editorInit" />
                                  <br />
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete v-model="editedItem.role_ids" :items="userRolesFilter"
                                    item-text="display_name" item-value="id" clearable chips small-chips deletable-chips
                                    label="Role(s)*" multiple required
                                    :rules="validationRules.userRoleRules"></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <ModuleMedia moduleType="image" moduleOf="user" moduleMultiple="false"
                          :moduleImages="moduleImages" :moduleImagesOld="moduleImagesOld"></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors != undefined && errors.length >= 0" role="alert"
                      v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0" />
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table">
              </v-skeleton-loader>
              <v-card-title>
                Total Users ({{ totalRecords }})
                <v-spacer></v-spacer>
                <!-- <v-text-field @keydown.enter="searchQueryData" append-icon="search" label="Search" single-line
                  hide-details></v-text-field> -->
              </v-card-title>
              <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                :server-items-length="totalRecords" :footer-props="{
                itemsPerPageOptions: rowsPerPageItems,
                showFirstLastPage: true
              }" :loading="loading" v-show="!isLoaded" mobile-breakpoint="800" class="elevation-0">
                <template v-slot:item.department="{ item }">
                  <div class="text-truncate">
                    <span v-if="item.department == 0">All</span>
                    <span v-if="item.department == 1">Editing Team</span>
                    <span v-if="item.department == 2">Production Team</span>
                    <span v-if="item.department == 3">Marketing and Publicity Team</span>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ moment(item.created_at).fromNow() }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate" style="display: flex; justify-content: space-evenly;">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-switch style="transform: translateX(90px)" class="ml-3" v-model="item.is_approved" :label="'Approved'"
                          @change="toggleApproval(item)"></v-switch>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="hasPermission('users.delete')" style="margin: 10px !important;" color="red" dark @click="deleteItem(item)"
                          class="mr-2" v-on="on">
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FRONTENDUSERS_LIST,
  CREATE_FRONTENDUSER,
  UPDATE_FRONTENDUSER,
  CLEAR_FRONTENDUSER_ERRORS,
  DELETE_FRONTENDUSER,
  GET_FRONTENDUSER_DETAILS,
  TGGL_APPROVAL
} from "@/core/services/store/frontendusers.module";
// import {bus} from "@/main";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";
import { ROLES_SIMPLE_LIST } from "@/core/services/store/roles.module";
import { bus } from "@/main";
// import Confirmation from "@/view/content/Confirmation";

export default {
  name: "users",
  components: {
    ModuleMedia
  },
  data() {
    return {
      editorInit: {
        branding: false,
        height: 500,
        menubar: false,
        automatic_uploads: false,
        plugins: [
          "advlist autolink lists code link charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount fullscreen"
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | code | link  | fullscreen"
      },
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleImagesOld: [],
      userRolesFilter: [],
      headers: [
        {
          text: "Name",
          value: "name",
          name: "name",
          filterable: true,
          sortable: false
        },
        {
          text: "Email",
          value: "email",
          name: "email",
          filterable: true,
          sortable: false
        },
        {
          text: "",
          value: "",
          name: "",
          filterable: true,
          sortable: false
        },
        {
          text: "",
          value: "",
          name: "desination",
          filterable: true,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true
        },
        {
          text: "Action",
          value: "actions",
          name: "name",
          filterable: true,
          sortable: false
        }
      ],
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 50) ||
            "Must be less than or equal to 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        emailRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 100) ||
            "Must be less than or equal to 100 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters",
          v => /.+@.+\..+/.test(v) || "Must be valid"
        ],
        designationRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 100) ||
            "Must be less than or equal to 100 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        descriptionRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 1500) ||
            "Must be less than or equal to 1500 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        passwordRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 20) ||
            "Must be less than or equal to 20 characters",
          v => (v && v.length >= 6) || "Must be at least 6 characters"
        ],
        userRoleRules: [
          v => {
            if (!v || v.length < 1) return "Select Role(s)";
            else return true;
          }
        ]
      },
      items: [],
      dialog: false,
      show: false,
      editedItem: {},
      departments: [
        { value: "0", title: "All" },
        { value: "1", title: "Editing Team" },
        { value: "2", title: "Production Team" },
        { value: "3", title: "Marketing and Publicity Team" }
      ]
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_FRONTENDUSER_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.editedItem.description = '';
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
        i +
        "][data]=" +
        this.headers[i].value +
        "&columns[" +
        i +
        "][name]=" +
        this.headers[i].name +
        "&columns[" +
        i +
        "][searchable]=" +
        this.headers[i].filterable +
        "&columns[" +
        i +
        "][orderable]=" +
        this.headers[i].sortable +
        "&columns[" +
        i +
        "][search][value]=" +
        "&columns[" +
        i +
        "][search][regex]=" +
        false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Frontend Users", route: "frontend-users" },
      { title: "Frontend users" }
    ]);
    bus.$on("moduleImagesUploaded", moduleImages => {
      this.moduleImages = moduleImages.arr;
    });
    bus.$on("moduleImagesUploadedOld", moduleImagesOld => {
      this.moduleImagesOld = moduleImagesOld.arr;
    });
    this.getUserRoles();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map(key => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          (params.sortBy.length === 0 ? 4 : this.headerindex) +
          "&order[0][dir]=" +
          (params.sortDesc[0] ? "desc" : "asc");
        this.$store
          .dispatch(FRONTENDUSERS_LIST, { q: query, columns: this.columns })
          .then(res => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total
            });
          });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_FRONTENDUSER_DETAILS, item.id).then(data => {
        let userImages = [];
        this.editedItem = data.records || {};
        if (data.records.image_public_url !== null) {
          userImages.push(data.records);
        }
        this.moduleImagesOld = userImages;
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      if (this.moduleImages.length > 0) {
        let mFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
              .dispatch("delete_uploaded_image", {
                files: mFiles,
                type: "modal"
              })
              .then(() => {
                that.moduleImages = [];
                that.dialog = false;
                bus.$emit("moduleImagesDeletedByModal");
              });
          }
        });
      } else {
        this.dialog = !this.dialog;
        // bus.$emit('moduleModalClosed');
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_FRONTENDUSER;
        let id = item.id;
        this.$set(item, "image", this.moduleImages);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_FRONTENDUSER;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_FRONTENDUSER_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.name + "'?",
        clr: "red",
        callback: function () {
          let id = item.id;
          this.$store
            .dispatch(DELETE_FRONTENDUSER, {
              user_id: id
            })
            .then(() => {
              that.loadData().then(response => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
        }
      });
    },
    toggleApproval(item) {
      // Toggle the is_approved status
      let updatedItem = { ...item, is_approved: !item.is_approved };

      // Dispatch the toggle approval action
      this.$store.dispatch(TGGL_APPROVAL, { id: item.id, is_approved: updatedItem.is_approved }).then(() => {
        // After success, reload the data
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      });
    },
    
    getUserRoles() {
      this.$store.dispatch(ROLES_SIMPLE_LIST).then(data => {
        this.userRolesFilter = data.records.rolesList;
      });
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.users.errors
    })
  }
};
</script>
