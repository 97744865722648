import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            auth: true,
            role: "admin",
            permission: "dashboard.read"
          },
          component: () => import("@/view/pages/admin/dashboard/Dashboard.vue")
        },
       
        {
          path: "/frontend-users",
          redirect: "/frontend-users",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/frontend-users",
              name: "frontend-users",
              meta: {
                auth: true,
                role: "admin",
                permission: "users.read"
              },
              component: () =>
                import("@/view/pages/admin/frontend-users/FrontendUsers")
            }
          ]
        },
        {
          path: "/team-management",
          name: "team-management",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "team-members",
              name: "team-members",
              meta: {
                auth: true,
                role: "admin",
                permission: "users.read"
              },
              component: () => import("@/view/pages/admin/users/Users")
            },
            {
              path: "roles",
              name: "roles",
              meta: {
                auth: true,
                role: "admin",
                permission: "roles.read"
              },
              component: () => import("@/view/pages/admin/roles/Roles")
            },
            {
              path: "permissions",
              name: "permissions",
              meta: {
                auth: true,
                role: "admin",
                permission: "permissions.read"
              },
              component: () =>
                import("@/view/pages/admin/permissions/Permissions")
            }
          ]
        },
        {
          path: "/books-management",
          redirect: "/books-management",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/books-management",
              name: "books-management",
              meta: {
                auth: true,
                role: "admin",
                permission: "books.read"
              },
              component: () =>
                import("@/view/pages/admin/books-management/Books")
            }
          ]
        },
        {
          path: "/series-management",
          redirect: "/series-management",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/series-management",
              name: "series-management",
              meta: {
                auth: true,
                role: "admin",
                permission: "series.read"
              },
              component: () =>
                import("@/view/pages/admin/series-management/SeriesManagement")
            }
          ]
        },
        {
          path: "/sell-sheets",
          redirect: "/sell-sheets",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/sell-sheets",
              name: "sell-sheets",
              meta: {
                auth: true,
                role: "admin",
                permission: "sell_sheets.read"
              },
              component: () =>
                import("@/view/pages/admin/sell-sheets/SellSheets")
            }
          ]
        },
        {
          path: "/inquiries",
          redirect: "/inquiries",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/inquiries",
              name: "inquiries",
              meta: {
                auth: true,
                role: "admin",
                permission: "inquiries.read"
              },
              component: () =>
                import("@/view/pages/admin/inquiries/Inquiries")
            }
          ]
        },
        {
          path: "/authors-management",
          redirect: "/authors-management",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/authors-management",
              name: "authors-management",
              meta: {
                auth: true,
                role: "admin",
                permission: "authors.read"
              },
              component: () =>
                import(
                  "@/view/pages/admin/authors-management/AuthorsManagement"
                )
            }
          ]
        },
        {
          path: "/categories",
          redirect: "/categories",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/categories",
              name: "categories",
              meta: {
                auth: true,
                role: "admin",
                permission: "categories.read"
              },
              component: () =>
                import("@/view/pages/admin/categories/Categories")
            }
          ]
        },
        {
          path: "/filters-management",
          name: "filters-management",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "filters",
              name: "filters",
              meta: {
                auth: true,
                role: "admin",
                permission: "filters.read"
              },
              component: () =>
                import("@/view/pages/admin/filters-management/Filters")
            },
            {
              path: "filter-values",
              name: "filter-values",
              meta: {
                auth: true,
                role: "admin",
                permission: "filtervalues.read"
              },
              component: () =>
                import("@/view/pages/admin/filters-management/FilterValues")
            }
          ]
        },
        {
          path: "/content-management",
          name: "content-management",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "banners",
              name: "banners",
              meta: {
                auth: true,
                role: "admin",
                permission: "banners.read"
              },
              component: () =>
                import("@/view/pages/admin/content-management/Banners")
            },
            {
              path: "home-page",
              name: "home-page",
              meta: {
                auth: true,
                role: "admin",
                permission: "homepagesettings.read"
              },
              component: () =>
                import("@/view/pages/admin/content-management/HomePage")
            },
            {
              path: "menus",
              name: "menus",
              meta: {
                auth: true,
                role: "admin",
                permission: "menus.read"
              },
              component: () =>
                import("@/view/pages/admin/content-management/Menus")
            },
            {
              path: "pages",
              name: "pages",
              meta: {
                auth: true,
                role: "admin",
                permission: "pages.read"
              },
              component: () =>
                import("@/view/pages/admin/content-management/Pages")
            },
            {
              path: "faqs",
              name: "faqs",
              meta: {
                auth: true,
                role: "admin",
                permission: "faqs.read"
              },
              component: () =>
                import("@/view/pages/admin/content-management/Faqs")
            }
          ]
        },
        // {
        //     path: "/redirects",
        //     redirect: "/redirects",
        //     component: () => import("@/view/pages/admin/Vuetify.vue"),
        //     children: [
        //         {
        //             path: "/redirects",
        //             name: "redirects",
        //             meta: {
        //                 auth: true,
        //                 role: 'admin',
        //                 permission: 'redirects.read'
        //             },
        //             component: () => import("@/view/pages/admin/redirects/Redirects")
        //         }
        //     ]
        // },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "site-settings",
              name: "site-settings",
              meta: {
                auth: true,
                role: "admin",
                permission: "sitesettings.read"
              },
              component: () =>
                import("@/view/pages/admin/settings/SiteSettings")
            },
            {
              path: "admin-settings",
              name: "admin-settings",
              meta: {
                auth: true,
                role: "admin",
                permission: "adminsettings.read"
              },
              component: () =>
                import("@/view/pages/admin/settings/AdminSettings")
            }
          ]
        },
        {
          path: "/email-templates",
          redirect: "/email-templates",
          component: () => import("@/view/pages/admin/Vuetify.vue"),
          children: [
            {
              path: "/email-templates",
              name: "email-templates",
              meta: {
                auth: true,
                role: "admin",
                permission: "pages.read"
              },
              component: () =>
                import("@/view/pages/admin/email-template/EmailTemplate")
            }
          ]
        },
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          meta: { auth: false },
          component: () => import("@/view/pages/auth/Login")
        }
        // {
        //     name: "register",
        //     path: "/register",
        //     component: () => import("@/view/pages/auth/Register")
        // }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
