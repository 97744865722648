<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="viewDialog" max-width="600px">
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeViewDialog"></i>
                  <v-card-text>
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div class="
                            symbol symbol-60 symbol-xxl-100
                            mr-5
                            align-self-start align-self-xxl-center
                          ">
                          <div class="symbol-label" v-bind:style="{
                            backgroundImage:
                              'url(' + editedItem.image_public_url + ')',
                          }"></div>
                        </div>
                        <div>
                          <a class="
                              font-weight-bolder font-size-h5
                              text-dark-75 text-hover-primary
                            ">
                            {{ editedItem.name }}</a>
                        </div>
                      </div>
                      <div class="py-9">
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Award winning:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.award_winning == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">International best selling:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.international_best_selling == 1
                              ? "Yes"
                              : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">NY times best selling:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.ny_times_best_selling == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">USA today best selling:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.usa_today_best_selling == 1
                              ? "Yes"
                              : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Enabled:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.enabled == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Illustrator:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.is_illustrator == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Featured:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.featured == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Name:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.name
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Real name:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.real_name
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Email:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.email
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Contact ID:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.contact_id
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Twitter URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.twitter_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Facebook URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.facebook_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Instagram URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.google_plus_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Youtube URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.youtube_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Vimeo URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.vimeo_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Website URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.wordpress_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Pinterest URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.pinterest_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Linkedin URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.linkedin_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Blogger URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.blogger_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Amazon URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.amazon_url
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Agented URL:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.agented_by
                          }}</a>
                        </div>
                        <div class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          ">
                          <span class="font-weight-bold mr-2">Biography:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.biography
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog persistent v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="primary" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn v-if="hasPermission('authors.create')" color="primary" dark class="ma-3 ml-0" v-on="on">
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit {{ editedItem.name }}</span>
                    <span v-else>Create Author</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form ref="form" v-model="validationRules.valid" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="editedItem.award_winning" :items="ques" item-text="title"
                                    item-value="value" label="Award Winning Author">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="
                                    editedItem.international_best_selling
                                  " :items="ques" item-text="title" item-value="value"
                                    label="International Best Selling Author">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="editedItem.ny_times_best_selling" :items="ques"
                                    item-text="title" item-value="value" label="NY Times Best Selling Author">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="editedItem.usa_today_best_selling" :items="ques"
                                    item-text="title" item-value="value" label="USA Today Best Selling Author">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="editedItem.featured" :items="ques" item-text="title"
                                    item-value="value" label="Featured">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="editedItem.enabled" :items="ques" item-text="title"
                                    item-value="value" label="Enabled">
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select clearable v-model="editedItem.is_illustrator" :items="ques"
                                    item-text="title" item-value="value" label="Illustrator">
                                  </v-select>
                                </v-col>

                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.name" :counter="50"
                                    :rules="validationRules.nameRules" label="Name*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.email" :counter="100" label="Email"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea v-model="editedItem.biography" label="Biography" :counter="2000"
                                    maxLength="2000"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.real_name" :counter="50" maxLength="50"
                                    label="Real Name"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.contact_id" :counter="50" maxLength="50"
                                    label="Contact ID"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.twitter_url" :counter="200" maxLength="200"
                                    label="Twitter Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.facebook_url" :counter="200" maxLength="200"
                                    label="Facebook Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.google_plus_url" :counter="200" maxLength="200"
                                    label="Instagram Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.youtube_url" :counter="200" maxLength="200"
                                    label="Youtube Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.vimeo_url" :counter="200" maxLength="200"
                                    label="Vimeo Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.wordpress_url" :counter="200" maxLength="200"
                                    label="Website Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.pinterest_url" :counter="200" maxLength="200"
                                    label="Pinterest Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.linkedin_url" :counter="200" maxLength="200"
                                    label="Linkedin Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.blogger_url" :counter="200" maxLength="200"
                                    label="Blogger Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.amazon_url" :counter="200" maxLength="200"
                                    label="Amazon Url"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field v-model="editedItem.agented_by" :counter="200" maxLength="200"
                                    label="Agented By"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <ModuleMedia moduleType="image" moduleOf="author" moduleMultiple="false"
                          :moduleImages="moduleImages" :moduleImagesOld="moduleImagesOld"></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors != undefined && errors.length >= 0" role="alert"
                      v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin: 0" />
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table">
              </v-skeleton-loader>
              <v-card-title>
                Authors ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field @keydown.enter="searchQueryData" append-icon="search" label="Search" single-line
                  hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                :server-items-length="totalRecords" :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true,
                }" :loading="loading" v-show="!isLoaded" mobile-breakpoint="800" class="elevation-0">
                <template v-slot:item.name="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.name"></span>
                  </div>
                </template>
                <template v-slot:item.enabled="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.enabled == 0">No</p>
                    <p v-if="item.enabled == 1">Yes</p>
                  </div>
                </template>
                <template v-slot:item.is_illustrator="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.is_illustrator == 0">No</p>
                    <p v-if="item.is_illustrator == 1">Yes</p>
                  </div>
                </template>
                <template v-slot:item.featured="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.featured == 0">No</p>
                    <p v-if="item.featured == 1">Yes</p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ moment(item.created_at).fromNow() }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="hasPermission('authors.edit')" color="primary" @click="showEditDialog(item)" dark
                          class="mr-2" v-on="on">
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="hasPermission('authors.delete')" color="red" dark @click="deleteItem(item)"
                          class="mr-2" v-on="on">
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-if="hasPermission('authors.view')" color="warning" dark @click="viewItemDialog(item)"
                          class="mr-2" v-on="on">
                          <v-icon dark small>fas fa-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  AUTHORS_LIST,
  CREATE_AUTHOR,
  UPDATE_AUTHOR,
  CLEAR_AUTHOR_ERRORS,
  DELETE_AUTHOR,
  GET_AUTHOR_DETAILS,
} from "@/core/services/store/authors.module";
// import {bus} from "@/main";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";
import { bus } from "@/main";
// import Confirmation from "@/view/content/Confirmation";

export default {
  name: "authors",
  components: {
    ModuleMedia,
  },
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc",
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleImagesOld: [],
      headerindex: "",
      headers: [
        {
          text: "Name",
          value: "name",
          name: "name",
          filterable: true,
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          name: "email",
          filterable: true,
          sortable: false,
        },
        {
          text: "Featured",
          value: "featured",
          name: "featured",
          filterable: true,
          sortable: false,
        },
        {
          text: "Enabled",
          value: "enabled",
          name: "enabled",
          filterable: true,
          sortable: false,
        },
        {
          text: "Illustrator",
          value: "is_illustrator",
          name: "is_illustrator",
          filterable: false,
          sortable: false,
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
        },
        {
          text: "Action",
          value: "actions",
          name: "title",
          filterable: false,
          sortable: false,
        },
      ],
      validationRules: {
        valid: true,
        nameRules: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length <= 50) ||
            "Must be less than or equal to 50 characters",
          (v) => (v && v.length >= 3) || "Must be at least 3 characters",
        ],
        // emailRules: [
        //   v => !!v || "This field is required",
        //   v => (v && v.length <= 100) || "Must be less than or equal to 100 characters",
        //   v => (v && v.length >= 3) || "Must be at least 3 characters",
        //   v => /.+@.+\..+/.test(v) || "Must be valid"
        // ]
      },
      items: [],
      dialog: false,
      viewDialog: false,
      editedItem: {},
      ques: [
        { value: "0", title: "No" },
        { value: "1", title: "Yes" },
      ],
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then((response) => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true,
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_AUTHOR_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
    },
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
        i +
        "][data]=" +
        this.headers[i].value +
        "&columns[" +
        i +
        "][name]=" +
        this.headers[i].name +
        "&columns[" +
        i +
        "][searchable]=" +
        this.headers[i].filterable +
        "&columns[" +
        i +
        "][orderable]=" +
        this.headers[i].sortable +
        "&columns[" +
        i +
        "][search][value]=" +
        "&columns[" +
        i +
        "][search][regex]=" +
        false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Authors Management", route: "authors-management" },
      { title: "Authors" },
    ]);
    bus.$on("moduleImagesUploaded", (moduleImages) => {
      this.moduleImages = moduleImages.arr;
    });
    bus.$on("moduleImagesUploadedOld", (moduleImagesOld) => {
      this.moduleImagesOld = moduleImagesOld.arr;
    });
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map((key) => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          (params.sortBy.length === 0 ? 5 : this.headerindex) +
          "&order[0][dir]=" +
          (params.sortDesc[0] ? "desc" : "asc");
        this.$store
          .dispatch(AUTHORS_LIST, { q: query, columns: this.columns })
          .then((res) => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total,
            });
          });
      });
    },
    refreshData() {
      this.loadData().then((response) => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_AUTHOR_DETAILS, item.id).then((data) => {
        let authorImages = [];
        this.editedItem = data.records || {};
        // console.log(this.editedItem);
        if (data.records.image_public_url !== null) {
          authorImages.push(data.records);
        }
        this.moduleImagesOld = authorImages;
        this.dialog = !this.dialog;
      });
    },
    viewItemDialog(item) {
      this.$store.dispatch(GET_AUTHOR_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        this.viewDialog = !this.viewDialog;
      });
    },
    closeViewDialog() {
      this.viewDialog = !this.viewDialog;
    },
    closeDialog() {
      if (this.moduleImages.length > 0) {
        let mFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
              .dispatch("delete_uploaded_image", {
                files: mFiles,
                type: "modal",
              })
              .then(() => {
                that.moduleImages = [];
                that.dialog = false;
                bus.$emit("moduleImagesDeletedByModal");
              });
          },
        });
      } else {
        this.dialog = !this.dialog;
        // bus.$emit('moduleModalClosed');
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_AUTHOR;
        let id = item.id;
        this.$set(item, "image", this.moduleImages);
        let data = {
          data: item,
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_AUTHOR;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_AUTHOR_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
          this.loadData().then((response) => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.name + "'?",
        clr: "red",
        callback: function () {
          let id = item.id;
          this.$store
            .dispatch(DELETE_AUTHOR, {
              author_id: id,
            })
            .then(() => {
              that.loadData().then((response) => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
        },
      });
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery,
      };
    },
    ...mapState({
      errors: (state) => state.authors.errors,
    }),
  },
};
</script>
