<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-select
            :items="mediaTypes"
            item-text="title"
            item-value="value"
            label="Media type"
            @change="changeMediaType"
          >
          </v-select>
        </v-col>
      </v-row>
      <hr />
      <v-row>
        <v-col cols="12">
          <div v-if="this.moduleType === 'file'">
            <ModuleFiles
              v-show="fileMediaType"
              :moduleFiles="moduleFiles"
              :moduleFilesOld="moduleFilesOld"
            ></ModuleFiles>
          </div>

          <div v-if="this.moduleType === 'image'">
            <ModuleImages
              v-show="imageMediaType"
              :moduleImages="moduleImages"
              :moduleOf="pModuleOf"
              :moduleMultiple="pModuleMultiple"
              :moduleImagesOld="moduleImagesOld"
            ></ModuleImages>
          </div>

          <div v-if="this.moduleType === 'video'">
            <ModuleVideos
              v-show="videoMediaType"
              :moduleVideos="moduleVideos"
              :moduleVideosOld="moduleVideosOld"
              :moduleVideosInput="moduleVideosInput"
            ></ModuleVideos>
          </div>

          <div v-if="this.moduleType === 'file-image'">
            <ModuleFiles
              v-show="fileMediaType"
              :moduleFiles="moduleFiles"
              :moduleFilesOld="moduleFilesOld"
            ></ModuleFiles>
            <ModuleImages
              v-show="imageMediaType"
              :moduleImages="moduleImages"
              :moduleImagesOld="moduleImagesOld"
            ></ModuleImages>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import {bus} from "@/main";
import ModuleFiles from "@/view/pages/admin/media/inc/ModuleFiles";
import ModuleImages from "@/view/pages/admin/media/inc/ModuleImages";
import ModuleVideos from "@/view/pages/admin/media/inc/ModuleVideos";

export default {
  name: "module-media",
  props: [
    "moduleType",
    "moduleOf",
    "moduleMultiple",
    "moduleFiles",
    "moduleFilesOld",
    "moduleImages",
    "moduleImagesOld",
    "moduleVideos",
    "moduleVideosOld",
    "moduleVideosInput"
  ],
  data() {
    return {
      fileMediaType: false,
      imageMediaType: false,
      videoMediaType: false,
      pModuleType: "",
      pModuleOf: "",
      pModuleMultiple: "",
      mediaTypes: []
    };
  },
  watch: {
    moduleType: {
      handler() {
        this.pModuleType = this.moduleType;
      },
      deep: true
    },
    moduleOf: {
      handler() {
        this.pModuleOf = this.moduleOf;
      },
      deep: true
    },
    moduleMultiple: {
      handler() {
        this.pModuleMultiple = this.moduleMultiple;
      },
      deep: true
    }
  },
  mounted() {
    this.pModuleType = this.moduleType;
    this.pModuleOf = this.moduleOf;
    this.pModuleMultiple = this.moduleMultiple;
    if (this.moduleType === "file") {
      this.mediaTypes = [{ value: "1", title: "File" }];
    } else if (this.moduleType === "image") {
      this.mediaTypes = [{ value: "2", title: "File" }];
    } else if (this.moduleType === "file-image") {
      this.mediaTypes = [
        { value: "1", title: "File" },
        { value: "2", title: "File" }
      ];
    }
  },
  methods: {
    changeMediaType(v) {
      if (v == 1) {
        this.fileMediaType = true;
        this.imageMediaType = false;
        this.videoMediaType = false;
      }
      if (v == 2) {
        this.imageMediaType = true;
        this.videoMediaType = false;
        this.fileMediaType = false;
      }
      if (v == 3) {
        this.videoMediaType = true;
        this.imageMediaType = false;
        this.fileMediaType = false;
      }
    }
  },
  components: {
    ModuleFiles,
    ModuleImages,
    ModuleVideos
  }
};
</script>
